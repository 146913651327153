@import url(https://fonts.googleapis.com/css?family=Roboto|Oswald|Raleway|Arimo);
@import url(https://fonts.googleapis.com/css?family=Merriweather);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500,700);
@import url(https://fonts.googleapis.com/css?family=Roboto|Oswald|Raleway|Arimo);
@import url(https://fonts.googleapis.com/css?family=Economica:700,400italic);


html {font-family: 'Roboto', sans-serif;}
.dropdown-content {min-width:200px;}
.headline1{font-family: 'Economica', sans-serif;}
.headline12{font-family: 'Economica', sans-serif; font-size: 1.2em;}

.container {width: 95%;}
td{padding: 8px 5px; border: 1px solid #c0c0c0;}

.card .card-content{
    padding-top: 24px;  padding-bottom:4px; padding-left: 24px; padding-right: 24px;
}

.btn-block {
    width: 100%;
}

.bordered-input-field {
  border: 1px solid #c0c0c0 !important;
  border-radius: 4px !important;
  text-align: left !important;
  padding: 0px 20px !important;
  width: calc(100% - 40px) !important;
  margin-bottom: 10px !important;
  height: 45px !important;
}

.bordered-input-field:focus {
  border: 1px solid #4db6ac !important;
}

.bordered-input-field-label {
  text-align: left;
  color: black;
  position: relative;
  display: inline-block;
  min-height: 24px;
}

.bordered-input-field-label > span {
  min-height: 20px;
}


.bordered-btn {
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  padding: 10px 20px 10px 20px;
  border: solid #4caf50 2px;
  text-decoration: none;
  background-color: #ffffff
}
